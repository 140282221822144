import * as colors from './colors'
import { lighten, darken, rgba } from 'polished'

// Themes (ThemeSelector Component)
export const themes = {
	default: {
		color: colors.textColor,
		background: colors.bgColor,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	black: {
		color: colors.lightGrey,
		background: colors.black,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	darkGreen: {
		color: colors.lightGrey,
		background: colors.darkGreen,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	teal: {
		color: colors.white,
		background: colors.teal,
		hoverColor: colors.offWhite,
		hoverBackground: darken(0.07, colors.teal)
	},
	charcoal: {
		color: colors.lightGrey,
		background: colors.charcoal,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	white: {
		color: colors.textColor,
		background: colors.white,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		hoverBackground: darken(0.07, colors.lightGrey)
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
	red: {
		color: colors.offWhite,
		background: colors.mainColor,
		hoverColor: colors.white,
		hoverBackground: colors.mainColor
	},
	darkRed: {
		color: colors.offWhite,
		background: colors.darkRed,
		hoverColor: colors.white,
		hoverBackground: colors.mainColor
	},
	maroon: {
		color: colors.offWhite,
		background: colors.maroon,
		hoverColor: colors.white,
		hoverBackground: colors.mainColor
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.2, colors.textColor)
	}
}

// Button Themes
export const buttonThemes = {
	default: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
	teal: {
		color: colors.bgColor,
		background: colors.teal,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.teal)
	},
	black: {
		color: colors.bgColor,
		background: colors.black,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	white: {
		color: colors.textColor,
		background: colors.white,
		hoverColor: colors.mainColor,
		hoverBackground: colors.lightGrey
	},
	tan: {
		color: colors.textColor,
		background: colors.tan,
		hoverColor: colors.bgColor,
		hoverBackground: colors.mainColor
	},
	buttonDefault: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		hoverBackground: colors.white
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.07, colors.mainColor)
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.2, colors.textColor)
	},
	transparent: {
		color: colors.textColor,
		background: colors.transparent,
		hoverColor: colors.mainColor,
		hoverBackground: colors.transparent
	},
	transparentLight: {
		color: rgba(colors.bgColor, 0.5),
		background: colors.transparent,
		hoverColor: colors.bgColor,
		hoverBackground: colors.transparent
	},
	secondary: {
		color: 'inherit',
		background: colors.transparent,
		hoverColor: colors.bgColor,
		hoverBackground: colors.mainColor,
		borderColor: 'currentcolor',
		borderHoverColor: colors.mainColor
	}
}

// Input Themes
export const inputThemes = {
	default: {
		color: colors.textColor,
		placeholderColor: colors.lightTextColor,
		background: colors.transparent,
		accentColor: colors.bgColor,
		hoverColor: colors.bgColor,
		borderColor: colors.textColor,
		hoverBorderColor: colors.textColor,
		focusBorderColor: colors.mainColor,
		hoverBackground: colors.transparent,
		focusBackground: colors.transparent,
	},
	lightGrey: {
		color: colors.lightGrey,
		placeholderColor: colors.lightGrey,
		background: colors.transparent,
		accentColor: colors.teal,
		hoverColor: colors.white,
		borderColor: colors.offWhite,
		hoverBorderColor: colors.offWhite,
		focusBorderColor: colors.offWhite,
		hoverBackground: colors.transparent,
		focusBackground: colors.transparent,
	}
}

export default themes
