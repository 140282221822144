export const margin = 100 / 14 + 'vw'
export const gutter = '3vw'

export const containerLargeMargins = '5vw'
export const containerMediumMargins = '5vw'
export const containerSmallMargins = '20px'

export const colSpacingSmall = '0'
export const colSpacingMedium = '0'
export const colSpacingLarge = '0'

export const rowSpacingSmall = colSpacingSmall
export const rowSpacingMedium = colSpacingMedium
export const rowSpacingLarge = colSpacingLarge

const gridSettings = {
	colGap: [colSpacingSmall, colSpacingMedium, colSpacingLarge],
	rowGap: [rowSpacingSmall, rowSpacingMedium, rowSpacingLarge],
	vAlign: 'center'
}

export default gridSettings
