import { lighten, darken, rgba } from 'polished'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
export const darkBrown = '#3E2B2F'
export const maroon = '#6F3332'
export const red = '#943337'//'#CE3438'
export const darkRed = '#943337'
export const darkGreen = '#212721'
export const green = '#294634'
export const lightTeal = '#81C6BC'
export const teal = '#00A990'
export const charcoal = '#2D2A26'
export const grey = '#969493'
export const tan = '#F2E4B1'
export const offWhite = '#F9F2D8'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = white
export const mainColor = red
export const alert = red
export const notify = tan
export const success = teal
export const textColor = charcoal
export const lightTextColor = rgba(textColor, 0.6)
export const lightGrey = offWhite
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
