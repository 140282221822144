import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import SharpMediumWoff from '../assets/fonts/sharp/SharpGroteskMedium20.woff'
import SharpMediumWoff2 from '../assets/fonts/sharp/SharpGroteskMedium20.woff2'

import OggRomanWoff from '../assets/fonts/ogg/ogg-roman.woff'
import OggRomanWoff2 from '../assets/fonts/ogg/ogg-roman.woff2'

import OggRomanItalicWoff from '../assets/fonts/ogg/ogg-Italic.woff'
import OggRomanItalicWoff2 from '../assets/fonts/ogg/ogg-Italic.woff2'

import OggBookWoff from '../assets/fonts/ogg/ogg-book.woff'
import OggBookWoff2 from '../assets/fonts/ogg/ogg-book.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Sharp = 'Sharp'
export const SharpMediumFont = fontFace(Sharp, SharpMediumWoff, SharpMediumWoff2)

export const Ogg = 'Ogg'
export const OggRomanFont = fontFace(Ogg, OggRomanWoff, OggRomanWoff2)
export const OggRomanItalicFont = fontFace(Ogg, OggRomanItalicWoff, OggRomanItalicWoff2, 'normal', 'italic')

export const OggBook = 'Ogg Text'
export const OggBookFont = fontFace(OggBook, OggBookWoff, OggBookWoff2)
